import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const imgStyle = {
  height: '40vh',
};

const HeroImage = ({ children, img }) => {
  return (
    <BackgroundImage
      fluid={img}
      style={imgStyle}
      className="d-flex justify-content-center align-items-center"
    >
      {children}
    </BackgroundImage>
  );
};

export default HeroImage;
