import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <div className="bg-light">
      <Container>
        <Row className="text-center">
          <Col sm={4}>
            <p className="mt-3">
              <Link to="/" className="text-success text-decoration-none">
                &raquo; Home
              </Link>
            </p>
            <p>
              <Link
                to="/producten"
                className="text-success text-decoration-none"
              >
                &raquo; Producten
              </Link>
            </p>
            <p>
              <Link to="/blog" className="text-success text-decoration-none">
                &raquo; Blog
              </Link>
            </p>
          </Col>
          <Col sm={4}>
            <p className="mt-3">
              <Link to="/zoeken" className="text-success text-decoration-none">
                &raquo; Zoeken
              </Link>
            </p>
            <p>
              <Link to="/over" className="text-success text-decoration-none">
                &raquo; Over
              </Link>
            </p>
            <p>
              <Link to="/contact" className="text-success text-decoration-none">
                &raquo; Contact
              </Link>
            </p>
          </Col>
          <Col sm={4}>
            <p className="mt-3">
              <a
                href="https://www.facebook.com/"
                className="text-success text-decoration-none"
              >
                &raquo; Facebook
              </a>
            </p>
            <p>
              <a
                href="https://www.twitter.com"
                className="text-success text-decoration-none"
              >
                &raquo; Twitter
              </a>
            </p>
            <p>
              <a
                href="https://gatsby-store-poc.onrender.com/rss.xml"
                className="text-success text-decoration-none"
              >
                &raquo; RSS
              </a>
            </p>
          </Col>
        </Row>
        <p className="text-center pb-3 mb-0">
          &copy; {new Date().getFullYear()} - GatsbyStore
        </p>
      </Container>
    </div>
  );
};

export default Footer;
