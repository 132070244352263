import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Container, Nav, Navbar as NavbarBootstrap } from 'react-bootstrap';

const Navbar = ({ pageInfo }) => {
  const [itemCount, setItemCount] = useState(0);
  const navigationLinks = ['Producten', 'Blog', 'Zoeken', 'Over', 'Contact'];

  useEffect(() => {
    if (window.Snipcart) {
      // Update itemCount when switching pages
      setItemCount(window.Snipcart.api.items.count());

      // Update itemCount when you add or change items
      window.Snipcart.subscribe('cart.closed', () => {
        setItemCount(window.Snipcart.api.items.count());
      });

      // Update itemCount when refreshing the page
      window.Snipcart.subscribe('cart.ready', () => {
        setItemCount(window.Snipcart.api.items.count());
      });
    }

    return () => {
      window.Snipcart.unsubscribe('cart.closed');
      window.Snipcart.unsubscribe('cart.ready');
    };
  }, []);

  return (
    <NavbarBootstrap bg="light" expand="md">
      <Container>
        <Link to="/">
          <NavbarBootstrap.Brand as="span">GatsbyStore</NavbarBootstrap.Brand>
        </Link>
        <NavbarBootstrap.Toggle aria-controls="basic-navbar-nav" />
        <NavbarBootstrap.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {navigationLinks.map((link, index) => {
              return (
                <Nav activeKey={pageInfo && pageInfo.pageName} key={index}>
                  <Link
                    to={`/${link.toLowerCase()}`}
                    className="text-decoration-none"
                  >
                    <Nav.Link as="span" eventKey={link.toLowerCase()}>
                      {link}
                    </Nav.Link>
                  </Link>
                </Nav>
              );
            })}
            <Nav.Link className="snipcart-checkout">
              Winkelwagen [{itemCount}]
            </Nav.Link>
          </Nav>
        </NavbarBootstrap.Collapse>
      </Container>
    </NavbarBootstrap>
  );
};

Navbar.propTypes = {
  pageInfo: PropTypes.object,
};

export default Navbar;
